import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { BlogTable } from "../../../components/Blog/postElements/BlogTable";
import { BannerMobileApp } from "../../../components/Blog/postElements/BannerMobile";
import { PostIntro } from "../../../components/Blog/postElements/PostIntro";
import { ColumnContent } from "../../../components/Blog/postElements/ColumnContent";
import { ImageMaxWidth } from "../../../components/Blog/postElements/ImageMaxWidth";
import { FullWidthImage } from "../../../components/Blog/postElements/FullWidthImage";
import { Quote } from "../../../components/Blog/postElements/Quote";
export const _frontmatter = {
  "title": "Business: Easily Add WebRTC Video-Chat Capabilities To Your App",
  "authors": ["Piotr Gąsiorkiewicz"],
  "abstract": "Every year, the popularity and variety of uses for audio-visual communication increases. Delivering services online has many benefits for industries like healthcare and others. The intensity of the shift towards online services accelerated when Covid-19 began affecting all our lives.",
  "imageSource": "./main.png",
  "link": "what-is-webrtc-part-1-benefits",
  "date": "2022-02-14T06:32:51.343Z",
  "seoTitle": "Business: Easily Add WebRTC Video-Chat Capabilities To Your App",
  "seoDescription": "WebRTC technology that allows you to establish a direct P2P, lossless audio/video connection. Where is WebRTC used? What are the WebRTC limitations? How does WebRTC works? Read the article and learn everything about this technology.",
  "seoKeyPhrase": "What is WebRTC and its benefits",
  "seoKeyWords": "WebRTC, Twillio, Google, P2P, Telemedicine, Video chat, audio, video, teleconferencing, tele conference, tele-medicine apps, how to build audio video app, how to build telemedicine, how to integrate videa chat",
  "categories": ["Business", "WebRTC", "Video-Chat", "Telemedicine"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PostIntro title="Introduction" description={<p>Every year, the popularity and variety of uses for audio-visual communication increases. Delivering services online has many benefits for industries like healthcare and others. The intensity of the shift towards online services accelerated when Covid-19 began affecting all our lives.</p>} imageUrl={"/img/easilyAddWebRTC/main.png"} mdxType="PostIntro" />
    <h2 {...{
      "id": "Whats-the-easiest-way-to-implement-a-video-conference-service-in-your-product-or-organization"
    }}>{`What’s the easiest way to implement a video-conference service in your product or organization?`}</h2>
    <p><a parentName="p" {...{
        "href": "https://webrtc.org/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`WebRTC`}</a>{` may be the answer to this question.`}</p>
    <p>{`Many of the world`}{`’`}{`s largest technology firms, including `}<strong parentName="p">{`Apple`}</strong>{`, `}<strong parentName="p">{`Google`}</strong>{`, and `}<strong parentName="p">{`Microsoft`}</strong>{`, endorse WebRTC as a global standard. It`}{`’`}{`s a free, open-source technology that allows you to add audio-video capabilities to many types of products.`}</p>
    <p>{`WebRTC offers `}<strong parentName="p">{`voice calling`}</strong>{` , `}<strong parentName="p">{`video conferencing`}</strong>{` and `}<strong parentName="p">{`file sharing`}</strong>{` in real-time with no external applications or extra browser plugins.`}</p>
    <p>{`Some of the most popular, high-quality products that use WebRTC are `}<strong parentName="p">{`Google, Facebook, Amazon, BitTorrent`}</strong>{` and `}<strong parentName="p">{`Discord`}</strong>{`. Our company, `}<a parentName="p" {...{
        "href": "https://allbright.io/",
        "target": "_blank",
        "rel": "nofollow"
      }}><strong parentName="a">{`AllBright.io`}</strong></a>{`, also uses this technology. You can see an example of its use in our audio-video application called `}<a parentName="p" {...{
        "href": "https://captio.cc/en/",
        "target": "_blank",
        "rel": "nofollow"
      }}><strong parentName="a">{`Captio`}</strong></a>{` (captio.cc).`}</p>
    <h2 {...{
      "id": "Why-is-WebRTC-good-for-businesses"
    }}>{`Why is WebRTC good for businesses?`}</h2>
    <p>{`Use of the `}<strong parentName="p">{`peer-to-peer`}</strong>{` connection model is what makes WebRTC a powerful technology.`}</p>
    <p><strong parentName="p">{`Peer-to-peer`}</strong>{` connection (or `}{`”`}<strong parentName="p">{`P2P`}</strong>{`”`}{`) is a communication model where every single device in the network is equivalent. Every user connected to the P2P network can send (as long as they have the appropriate permissions) and receive data.`}</p>
    <p>{`Each device connected to the network may act as a server and a client too. This combination means it`}{`’`}{`s possible to download files and share them with other computers on the same network in parallel. Within the communication process, the external server role is minimised. The external server is only needed to establish a connection between two devices. Once that`}{`’`}{`s done, the server`}{`’`}{`s part is complete. Users then `}<strong parentName="p">{`send data to each other directly`}</strong>{` , which minimises delays. WebRTC is a `}{`’`}{` `}<strong parentName="p">{`real-time communication`}</strong>{`’`}{` technology because P2P reduces data transmission lags, and latency is almost imperceptible.`}</p>
    <p>{`Peer-to-peer provides so much more in addition to this.`}</p>
    <BannerMobileApp title="" description={"Do you want to create a video-chat application?"} mdxType="BannerMobileApp" />
    <h2 {...{
      "id": "What-are-the-advantages-of-this-technology-over-the-competition"
    }}>{`What are the advantages of this technology over the competition?`}</h2>
    <p>{`The benefits of WebRTC are so very numerous that, in the field of streaming audio-video, there`}{`’`}{`s almost no legitimate competition. Now let`}{`’`}{`s go through the WebRTC benefits and see what they provide in practice.`}</p>
    <h3 {...{
      "id": "WebRTC-is-free"
    }}>{`WebRTC is free`}</h3>
    <p>{`WebRTC is open source, which means that developers and companies worldwide support this technology by getting involved in its development. Due to this, WebRTC is getting better and better every year. After all, free and excellent quality means many providers - such as Twillio, Quickblox and Agora - use WebRTC to help deliver their services.`}</p>
    <h3 {...{
      "id": "WebRTC-has-great-support-from-the-developer-community"
    }}>{`WebRTC has great support from the developer community`}</h3>
    <p>{`WebRTC is open source and that means that developers and companies around the world support this technology by getting involved in its development. For this reason, WebRTC is getting better and better every year. After all, free and very good quality means there are many providers like Twillio and Agora who can help deliver this service.`}</p>
    <h3 {...{
      "id": "WebRTC-is-compatible-with-the-most-popular-OSs-and-browsers"
    }}>{`WebRTC is compatible with the most popular OSs and browsers`}</h3>
    <p>{`Any device with access to the most popular modern browsers can use WebRTC. The technology does not cut us off from `}<strong parentName="p">{`Mac`}</strong>{` , `}<strong parentName="p">{`Windows`}</strong>{` , `}<strong parentName="p">{`Linux`}</strong>{` or `}<strong parentName="p">{`mobile`}</strong>{` system users. We are also not limited to specific browsers. Although WebRTC does not need external applications or plugins, we can create them if we wish.`}</p>
    <h3 {...{
      "id": "WebRTC-is-secure"
    }}>{`WebRTC is secure`}</h3>
    <p>{`WebRTC can uphold the highest security because there is `}<strong parentName="p">{`no intermediary layer`}</strong>{` to access the transmitted data. After establishing a connection, `}<strong parentName="p">{`data transfers between the connected peers only`}</strong>{`. Additionally, before using a physical device such as a microphone, `}<strong parentName="p">{`WebRTC needs to get permission`}</strong>{` from the user. Finally, the entire connection is encrypted so only the conversation participants can read it. If you are interested in `}<strong parentName="p">{`HIPAA`}</strong>{` , some WebRTC providers like Twilio will provide this as well. Get in touch with our team if you want to learn more about HIPAA compliance in audio-video products.`}</p>
    <h3 {...{
      "id": "WebRTC-offers-a-variety-of-features"
    }}>{`WebRTC offers a variety of features`}</h3>
    <p>{`WebRTC not only allows you to stream data such as `}<strong parentName="p">{`video`}</strong>{` and `}<strong parentName="p">{`audio`}</strong>{`. You can send `}<strong parentName="p">{`messages`}</strong>{` , `}<strong parentName="p">{`share a user`}{`’`}{`s screen`}</strong>{` , and even `}<strong parentName="p">{`transfer files`}</strong>{`.`}</p>
    <h3 {...{
      "id": "Low-latency"
    }}>{`Low latency`}</h3>
    <p>{`Reducing lag between users is one of the primary challenges when creating a video chat. As WebRTC eliminates the intermediary during a conversation, `}<strong parentName="p">{`lag is minimal`}</strong>{` and, in most cases, undetectable.`}</p>
    <h3 {...{
      "id": "Adaptive-to-the-internet-connection"
    }}>{`Adaptive to the internet connection`}</h3>
    <p>{`Stable, fast internet is not available everywhere. In the case of video chat, WebRTC can `}<strong parentName="p">{`negotiate the size of the data`}</strong>{` sent. If the connection quality deteriorates, we can lower the data rate to avoid any interference.`}</p>
    <h3 {...{
      "id": "High-video-quality"
    }}>{`High video quality`}</h3>
    <p>{`WebRTC uses codecs partly based on those used in Skype, such as iSAC, iLBC, Opus, and VP8. Consequently, `}<strong parentName="p">{`4k`}</strong>{` video and stereo audio streaming up to `}<strong parentName="p">{`48kHz`}</strong>{` are possible, meaning sound can contain the full human auditory range.`}</p>
    <Quote content={<p className="content">WebRTC provides great capabilities in times of online-first businesses. <br /> That&#39;s why we decided to use it in Captio, our video-chat project.</p>} author={<p className="author">Piotr Gąsierkiewicz Co-founder of <a href='/' target='_blank'>AllBright.io</a></p>} mdxType="Quote" />
    <h2 {...{
      "id": "How-WebRTC-is-used-in-business-products"
    }}>{`How WebRTC is used in business products`}</h2>
    <p><img parentName="p" {...{
        "src": "/img/easilyAddWebRTC/webrtc.png",
        "alt": "flutter architecture",
        "title": "WebRTC"
      }}></img></p>
    <h3 {...{
      "id": "HEALTHCARE"
    }}>{`HEALTHCARE`}</h3>
    <ColumnContent description={<p>Telemedicine is an approach that makes healthcare cheaper and more readily available to patients.<br /><br />According to an Accenture study, <strong>7 out of 10 patients</strong> prefer a health provider that offers interactions online.</p>} imageUrl={"/img/easilyAddWebRTC/healthcare.png"} mdxType="ColumnContent" />
    <p>{`Video appointments with a doctor are faster than an in-person visit. At the same time, they improve the options available to those for whom a regular doctor`}{`’`}{`s visit requires great effort or a long journey.`}</p>
    <p>{`Telemedicine is especially valuable when life gets busy. Concerns can arise, such as wasting time in a physical or virtual queue or perhaps being near many unwell people. WebRTC means more patients get appointments, and the waiting times reduce.`}</p>
    <h3 {...{
      "id": "Financial-Services"
    }}>{`Financial Services`}</h3>
    <ColumnContent description={<p>Research shows companies that support customer relations via many contact channels can retain <strong>89%</strong> of their customers.<br /><br />Today, in the era of widespread digitalisation, it&#39;s unwise to neglect remote customer service.</p>} imageUrl={"/img/easilyAddWebRTC/financial.png"} mdxType="ColumnContent" />
    <p>{`Thanks to easy screen sharing, consultants can now give customers a live demonstration to familiarise them with the financial organisation`}{`’`}{`s products and services. What`}{`’`}{`s more, one-to-one video calls help engage customers, build trust and provides a personalised experience with a human touch. For people who liked in-branch customer service, this goes a long way towards making up for what`}{`’`}{`s missing. It`}{`’`}{`s also appreciated by people with a preference for digital interaction. Video support sessions are a convenient way to fill gaps in understanding financial self-serve tools offered by banks, mortgage brokers, insurers, investment managers and other financial sector businesses.`}</p>
    <p>{`The premium service standards, vital to banks and other financial businesses, disappear when connections lag and become unstable. WebRTC helps eliminate latency and provides the best connection quality possible, so these companies can continue to deliver a high level of customer service.`}</p>
    <h3 {...{
      "id": "Education"
    }}>{`Education`}</h3>
    <ColumnContent description={<p>Nowadays, in times where remote schooling is widespread, a WebRTC-based solution can help students participate in their classes. However, remote teaching can lead to feelings of isolation, and video classes often bring welcome relief from this experience. Additionally, feelings of isolation disappear when video conferencing</p>} imageUrl={"/img/easilyAddWebRTC/education.png"} mdxType="ColumnContent" />
    <p>{`allows for meetings between students and teachers located further apart. Such an opportunity is much less easy to arrange when gatherings must take place in a physical location.`}</p>
    <p>{`With WebRTC, students can focus on the topic of the lesson without being distracted by technical glitches. The great video quality allows you to see what`}{`’`}{`s on the school blackboard and, for the teacher, what a student is pointing to in their notebook.`}</p>
    <p>{`The ability to stimulate engagement and collaboration between students and teachers is perhaps the most significant benefit of video conferencing in education. As a result, students may become more motivated and interested in their studies.`}</p>
    <p>{`In addition to audio and video, `}<strong parentName="p">{`screen sharing`}</strong>{` is a valuable option. This capability enables creating a virtual class with additional features such as a `}<strong parentName="p">{`virtual blackboard`}</strong>{` or a worksheet in a web browser where the instructor can monitor the progress of the students.`}</p>
    <h3 {...{
      "id": "Customer-service-video-calls"
    }}>{`Customer service video calls`}</h3>
    <ColumnContent description={<p>Customer service sessions over video give the customer a great feeling. After all, they have just had one-on-one attention, even if just for a few minutes. Also, if it&#39;s needed, both parties can present using screen sharing or a video camera. This raises the customer&#39;s sense of the quality of the service and can p;rovide a feeling of empowerment.</p>} imageUrl={"/img/easilyAddWebRTC/service.png"} mdxType="ColumnContent" />
    <p>{`It is much easier to solve these problems when a specialist can visit in person. Unfortunately, this support comes at a much higher cost. Also, discovering when the client is home, and the specialist is available is often inconvenient.`}</p>
    <p>{`Once again, `}<strong parentName="p">{`real-time video`}</strong>{` conferencing reduces costs and makes it much easier to locate the problem`}{`’`}{`s source and then provide solutions.`}</p>
    <h3 {...{
      "id": "Field-Services"
    }}>{`Field Services`}</h3>
    <ColumnContent description={<p>Production processes run smoothly only when the machinery involved works as intended. Picture an oil rig or a sophisticated manufacturing plant. Often, complex mechanised processes like these need constant attention from specialists who fix problems as they arise. Usually, there are fewer specialists than is </p>} imageUrl={"/img/easilyAddWebRTC/findservices.png"} mdxType="ColumnContent" />
    <p>{`necessary to keep production optimal the whole time.`}</p>
    <p>{`A few years ago, Google released a product named Google Glass. The manufacturing sector embraced it and, combined with WebRTC technology, it allowed experts to instruct technicians in real-time as complex manufacturing processes were performed. Google Glass is well-suited for those who want a hands-free computing assistant in industrial settings. Google Glass is ideal for picking up items and doing intricate assembly tasks. Other suitable settings for hands-free work that smart glasses can boost are quality assurance and in-person instruction.`}</p>
    <p>{`Of course, Google Glass is not an essential element. New applications created using WebRTC alone allow less experienced staff to solve comparatively complex problems.`}</p>
    <h3 {...{
      "id": "Gaming"
    }}>{`Gaming`}</h3>
    <ColumnContent description={<p>People love playing games and sharing emotions with friends and acquaintances. Games are fun and big business too. Nowadays, the gaming industry is associated with large amounts of money, and competitive games are the focus of much of it.</p>} imageUrl={"/img/easilyAddWebRTC/gaming.png"} mdxType="ColumnContent" />
    <p>{`The games typically feature high-speed, dynamic gameplay involving many participants, where a technical delay is unacceptable. Competing groups routinely perform coordinated actions while relying on `}<strong parentName="p">{`delay-free`}</strong>{` communication, especially by voice. That is why real-time communication with WebRTC is the standard for this industry.`}</p>
    <h2 {...{
      "id": "Compatibility"
    }}>{`Compatibility`}</h2>
    <p>{`There are two different ways to implement WebRTC. The first one is through a browser and the second one is within a mobile application.`}</p>
    <p>{`The browser is the default implementation of WebRTC, and it works with all the most popular internet browsers.`}</p>
    <p>{`What browsers is WebRTC compatible with?`}</p>
    <p>{`For desktop:`}</p>
    <FullWidthImage imageSrc="/img/easilyAddWebRTC/webrtcbrowser.png" imageAlt='webrtc browser support' customStyle={{
      marginTop: '0'
    }} mdxType="FullWidthImage" />
    <p>{`and…`}</p>
    <p>{`For mobile:`}</p>
    <FullWidthImage imageSrc="/img/easilyAddWebRTC/webrtcmobile.png" imageAlt='mobile browser support' customStyle={{
      marginTop: '0'
    }} mdxType="FullWidthImage" />
    <p>{`Mobile platforms support browser-implemented WebRTC, with some limitations. The default way of consuming content on mobile devices is through applications.`}</p>
    <p>{`The mobile application has many more possibilities than the web application. It can use components such as navigation, notifications and more. Moreover, even though all of the browsers listed officially support WebRTC, not all of them fully exploit the potential of the technology.`}</p>
    <p>{`If we want our product to offer the highest standard on a mobile platform. In that case, the best solution is a mobile application. Additionally, you can take advantage of WebRTC vendors like `}<a parentName="p" {...{
        "href": "https://www.twilio.com/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Twilio`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.agora.io/en/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Agora`}</a>{` or `}<a parentName="p" {...{
        "href": "https://quickblox.com/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Quickblox`}</a>{`, which greatly facilitate the implementation of such a service in your application.`}</p>
    <BannerMobileApp title="" description={"Do you want to build a video-chat Flutter app?"} mdxType="BannerMobileApp" />
    <h2 {...{
      "id": "Summary"
    }}>{`Summary`}</h2>
    <p>{`WebRTC is a fantastic technology that has proven its effectiveness many times. It is an incomparable tool offering the highest security standards and perfect connection quality. In addition, WebRTC has been on the market for many years, so many solutions are available to help you use this technology to the best effect.`}</p>
    <p>{`Having read this article, we hope you can now see the benefits of using this technology in your business.`}</p>
    <p>{`Part one ends here but the subject continues in part two. I invite you to the `}<a parentName="p" {...{
        "href": "/blog/webrtc-part-2-how-does-it-work"
      }}>{`second part`}</a>{` where I look at WebRTC using a more technological perspective.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      